<template>
    <div class="alert-container">
        <div v-for="item in alerts" :key="item.id">
            <div :class="['alert', item.type]" role="alert" v-if="isActive(item)">
                <button class="close-button" @click="closeAlert(item.id)">
                    <i class="fas fa-times"/>
                </button>
                <a :href="item.link" class="alert-link">
                    <div class="alert-content">
                        <i v-if="item.type === 'alert-success'" class="fas fa-check-circle"/>
                        <i v-if="item.type === 'alert-info'" class="fas fa-info-circle"/>
                        <i v-if="item.type === 'alert-warning'"
                           class="fas fa-exclamation-triangle"/>
                        <i v-if="item.type === 'alert-danger'" class="fas fa-exclamation-triangle"/>
                        <i v-if="item.type === 'alert-primary'" class="fas fa-info-circle"/>
                        <i v-if="item.type === 'alert-secondary'" class="fas fa-info-circle"/>
                        <i v-if="item.type === 'alert-dark'" class="fas fa-info-circle"/>
                        <h4 class="alert-heading">
                            {{ i18n(item.name) }}
                        </h4>
                    </div>
                    <p class="alert-content">
                        {{ i18n(item.description) }}
                    </p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@enso-ui/ui/src/modules/plugins/i18n';

export default {
    name: 'Alerts',
    inject: ['http', 'route'],
    data() {
        return {
            alerts: {},
            groupId: null,
        };
    },
    computed: {
        isActive() {
            return item => item.active === 1 && new Date(item.end_date) >= new Date();
        },
    },
    mounted() {
        this.fetch();
    },
    methods: {
        i18n,
        fetch() {
            this.http
                .get(this.route('alerts.userAlerts'))
                .then(({ data }) => {
                    this.alerts = data.alerts;
                    this.groupId = data.group_id;
                })
                .catch(this.errorHandler);
        },
        closeAlert(alertId) {
            this.alerts = this.alerts.filter(item => item.id !== alertId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.alert-container{
    margin-bottom: 1.1rem;
}
.alert {
    position: relative;
    padding: 0.5rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0.5rem 0.75rem 0.6rem;
}

.alert-heading {
    color: inherit;
    font-size: calc(1rem + 0.4vw);
    order: 1;
}
.alert-link {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.alert-content{
    font-size: .8rem;
    display: flex;
    align-items: center;
}
.alert-content i {
    margin-right: 0.5rem;
}

.close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
}
.close-button i {
    font-size: 1.2rem;
}

.alert-success {
    color: $darkGreen;
    background-color: $lightGreen;
    border-color: $mediumLightGreen;
}

.alert-info {
    color: $petrolBlue;
    background-color: $lightBlue;
    border-color: $mediumLightBlue;
}

.alert-warning {
    color: $brown;
    background-color: $skin;
    border-color: $darkSkin;
}

.alert-danger {
    color: $redWine;
    background-color: $lightPink;
    border-color: $darkPink;
}

.alert-primary {
    color: $mediumDarkBlue;
    background-color: $blueToneOne;
    border-color: $blueToneTwo;
}

.alert-secondary {
    color: $lightBlack;
    background-color: $lightGrey;
    border-color: $mediumLightGrey;
}
.alert-dark {
    color: $darkBlack;
    background-color: $grey;
    border-color: $darkGrey;
}
</style>

<template>
    <transition :name="transitionEffect">
        <div class="carousel-item" v-show="currentSlide === index"
             @mouseenter="$emit('mouseenter')"
             @mouseout="$emit('mouseout')">
            <img :src="slide" alt="slide">
        </div>
    </transition>
</template>

<script>
export default {
    name: 'CarouselItem',

    props: ['slide', 'currentSlide', 'index', 'direction'],

    emits: ['mouseenter', 'mouseout'],

    computed: {
        transitionEffect() {
            return this.direction === 'right' ? 'slide-out' : 'slide-in';
        },
    },
};

</script>

<style lang="scss" scoped>
.carousel-item {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
}

.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
    transition: all 1s ease-in-out;
}

.slide-in-enter-from {
    transition: translateX(-100%);
}

.slide-in-leave-to {
    transition: translateX(100%);
}

.slide-out-enter-from {
    transition: translateX(100%);
}

.slide-out-leave-to {
    transition: translateX(-100%);
}

</style>

<template>
    <div class="carousel">
        <div class="carousel-inner">
            <carousel-indicators
                v-if="indicators"
                :total="slides.length"
                :current-index="currentSlide"
                @switch="switchSlide($event)"/>
            <carousel-item
                v-for="(slide, index) in slides"
                :slide="slide"
                :key="`item-${index}`"
                :current-slide="currentSlide"
                :index="index"
                :direction="direction"
                @mouseenter="stopSlideTimer"
                @mouseout="startSlideTimer"/>
            <carousel-controls v-if="controls" @prev="prev" @next="next"/>
        </div>
    </div>
</template>

<script>
import CarouselItem from './CarouselItem.vue';
import CarouselControls from './CarouselControls.vue';
import CarouselIndicators from './CarouselIndicators.vue';

export default {
    name: 'Carousel',

    components: {
        CarouselItem,
        CarouselControls,
        CarouselIndicators,
    },

    props: {
        slides: {
            type: Array,
            required: true,
        },
        controls: {
            type: Boolean,
            default: false,
        },
        indicators: {
            type: Boolean,
            default: false,
        },
        interval: {
            type: Number,
            default: 5000,
        },
    },

    data: () => ({
        currentSlide: 0,
        slideInterval: null,
        direction: 'right',
    }),

    mounted() {
        this.startSlideTimer();
    },
    beforeUnmount() {
        this.stopSlideTimer();
    },

    methods: {
        setCurrentSlide(index) {
            this.currentSlide = index;
        },
        prev(step = -1) {
            const index = this.currentSlide > 0 ? this.currentSlide + step : this.slides.length - 1;
            this.setCurrentSlide(index);
            this.direction = 'left';
            this.startSlideTimer();
        },
        _next(step = 1) {
            const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + step : 0;
            this.setCurrentSlide(index);
            this.direction = 'right';
        },
        next(step = 1) {
            this._next(step);
            this.startSlideTimer();
        },
        startSlideTimer() {
            this.stopSlideTimer();
            this.slideInterval = setInterval(() => {
                this._next();
            }, this.interval);
        },

        stopSlideTimer() {
            clearInterval(this.slideInterval);
        },

        switchSlide(index) {
            const step = index - this.currentSlide;
            if (step > 0) {
                this.next(step);
            } else {
                this.prev(step);
            }
        },
    },
};

</script>

<style lang="scss" scoped>
.carousel {
    display: flex;
    justify-content: center;
}

.carousel-inner {
    position: relative;
    width: 900px;
    height: 400px;
    overflow: hidden;
}

@media (max-width: 1023px) {
    .carousel-inner {
        height: 180px;
    }
}

</style>

<template>
    <div>
        <div class="accept-eula" v-if="showEu">
            <div class="eula-content">
                {{ i18n('By using this app you accept the') }}
                <a :href="this.lang === 'es' ? '/docs/eulaEsp' : '/docs/eulau'" target="_blank">
                    {{ i18n('EULA') }}</a> <br>
                <a class="is-full-mobile is-half-tablet button-alignment"
                   @click="acceptEula">
                    <button class="button is-primary">
                        {{ i18n('Accept') }}
                    </button>
                </a>
            </div>
        </div>
        <div v-if="slides.length > 0">
            <carousel :slides="slides"
                      :interval="3000"
                      controls indicators/>
        </div>
        <div>
            <Alerts/>
        </div>
            <div class="container">
                <div class="content">
                    <div class="level-right">
                        <button class="button is-primary button-merits"
                                @click="$router.push('/meritUser/create')">
                            {{ i18n ('Give merits') }}
                        </button>
                    </div>
                    <UserPostsIndex/>
                </div>
            </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import Alerts from '@components/user/Alerts.vue';
import Carousel from '../banner/Carousel.vue';
import UserPostsIndex from '../posts/UserPostsIndex.vue';

export default {
    name: 'Index',
    components: {
        Carousel,
        Alerts,
        UserPostsIndex,
    },
    inject: ['errorHandler', 'route', 'i18n', 'toastr', 'http'],
    data: () => ({
        showEu: true,
        showSidebar: true,
        slides: [],
    }),
    computed: {
        ...mapGetters('preferences', ['lang']),
    },
    created() {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });

        // Refresh all open app tabs when a new service worker is installed.
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }

        this.showEu = window.localStorage.getItem('Eula') === null;
    },
    mounted() {
        this.listSlides();
    },
    methods: {
        ...mapMutations('layout', ['showFooter', 'hideFooter']),
        showRefreshUI(e) {
            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.promptPWA = true;
            this.registration = e.detail;
            this.snackBtnText = 'Refresh';
            this.snackWithBtnText = 'New version available!';
            this.snackWithButtons = true;
        },
        refreshApp() {
            // this.promptPWA = false;

            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) { return; }

            this.registration.waiting.postMessage('skipWaiting');
        },
        acceptEula() {
            window.localStorage.setItem('Eula', true);
            this.showEu = false;
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        listSlides() {
            this.http.get(this.route('bannerImages.list'))
                .then(({ data }) => {
                    this.bannerImages = data.bannerImagesList;
                    this.bannerImages.forEach(bannerImage => {
                        this.slides.push(this.route('bannerImages.downloadImage', bannerImage.id));
                    });
                })
                .catch(this.errorHandler);
        },
    },

};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.accept-eula{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $grey;
    z-index: 10000;
    padding: 2rem;
    .eula-content{
        position: relative;
        padding: 2rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        color: $secondary;
    }
}
.hide-sidebar-button {
    position: absolute;
    top: -30px;
    right: 4.1rem;
    z-index: 9999;
    padding: 5px 10px;
    background-color: $primary;
    color: $secondary;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.container {
    display: flex;
}

.content {
    flex: 1;
}

.sidebar {
    flex: 0 0 25%;
}

.slide-container {
    top: 0;
    right: 0;
    height: 100%;
    padding: 10px;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
    .hide-sidebar-button {
        right: 35%;
    }

    .sidebar {
        order: -1;
    }

    .content {
        order: 1;
    }

    .slide-container {
        position: static;
        height: auto;
        padding: 0;
    }
}

@media (min-width: 1023px) {
    .eula-content {
        padding-left: 180px !important;
    }
}

</style>
